import axios from 'axios';
const hostname = window && window.location && window.location.hostname
function environment(version){
    if(hostname==='127.0.0.1'||hostname==='localhost'){
        return `https://www.developcrmback.radiotrunk.com/api/${version}`
    }else if(hostname==='142.93.18.134:9000'){
        return `https://www.developcrmback.radiotrunk.com/api/${version}`
    }else if(hostname==='tickets.radiotrunk.com'){
        return `https://www.productioncrmback.radiotrunk.com/api/${version}`
    }
}

const instance =(version)=>{
    return axios.create({
    baseURL: environment(version),
    timeout: 0,
    headers: { 'Content-Type': 'application/json'}
})
};

export default{instance}