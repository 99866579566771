import axios from '../../mixins/axiosInstance.js'
import qs from 'qs';
const state = () => ({
    URI: '/extUsrs/login',
    username: '',
})

const getters = {}

const actions = {
    async login({ state }, data){
        axios.instance.post(state.URI, qs.stringify({
          username: data.username.toUpperCase(),
          password: data.pass
        })).then(function(response){
          localStorage.setItem('token', response.data.data.token)
          localStorage.setItem('username', response.data.data.username)
          localStorage.setItem('isAdmin', response.data.data.isAdmin)
          window.location.reload()
        }).catch(function(error){
          if(error.response){
            alert(error.response.data.error)
          }
          else{
            alert('Other error has occurred')
          }
        })
    },
}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}