import axios from '../mixins/axiosInstance.js';

export default {
    async isValidToken() {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.get('/extUsrs/testToken').catch((err) => {
            if(err.response.status){
                if(localStorage.token){
                    localStorage.removeItem('token');
                    window.location.reload();
                }
            }
        })
    }
}