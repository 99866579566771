import axios from "../../mixins/axiosInstance";
import qs from "qs";

const state = () => ({
  dialog: false,
  multipleTickets: false,
  items: [],
  files: [],
  phone_number: "",
  email: "",
  description: "",
  devices: [],
  devicesTable: [],
  device_id: null,
  ticket_id: 0,
  SelectedDeviceHasTicket: false,
  allDevices: false,
  headers: [
    {
        text: "ID", sortable: true, value: "device_id",divider:true
    },
    {
        text: "Dispositivo", sortable: true, value: "type",divider:true
    },
    
    {
        text: "Numero de serie", sortable: true, value: "SN",divider:true
    },
    {
        text: "Empresa", sortable: true, value: "company", divider:true
    },
    {
        text: "Cuenta", sortable: true, value: "account",divider:true
    },
    {
        text: "Sucursal", sortable: true, value: "username",divider:true
    }
]
});

const getters = {};

const actions = {
  async fetchItemsFromServer({ commit }) {
    let data;
    axios.instance.defaults.headers.common["token"] = localStorage.getItem(
      "token"
    );
    data = await axios.instance.get("/services?join=true");
    //console.log(data.data.data)
    if (data.data.data != "No registers detected") {
      commit("setServices", data.data.data);
    } else {
      commit("reloadItemState", false);
    }
  },
  async changePassword({ state, commit, dispatch }, data) {
    try {
      let response;
      console.log(state.dialog);
      axios.instance.defaults.headers.common["token"] = localStorage.getItem(
        "token"
      );
      response = await axios.instance.patch(
        "/extUsrs/tickets",
        qs.stringify({
          username: data.username,
          oldpassword: data.oldpassword,
          newpassword: data.newpassword,
        })
      );
      if (response.data.data) {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: response.data.data, type: "success" },
          { root: true }
        );
        commit("dialogState", false);
      }
    } catch (error) {
      if (error) {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: "Hubo un error al cambiar la contraseña", type: "error" },
          { root: true }
        );
      }
    }
  },
  async addTicket({ state, commit, dispatch }) {
    try {
      axios.instance.defaults.headers.common["token"] = localStorage.getItem(
        "token"
      );
      let response = await axios.instance.post(
        "/tickets",
        qs.stringify({
          comments: state.description,
          email: state.email,
          phone_number: state.phone_number,
          device_id: state.device_id,
        })
      );
      if (response.data.data) {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: response.data.data.message, type: "success" },
          { root: true }
        );
        commit("setTicketId", response.data.data.ticket_id);
      }
    } catch (error) {
      if (error == "Error: Request failed with status code 500") {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: "Este dispositivo ya tiene un ticket activo", type: "error" },
          { root: true }
        );
      } else {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: "Hubo un error al crear el ticket", type: "error" },
          { root: true }
        );
      }
    }
  },
  async addImagesTickets({ state, dispatch }) {
    try {
      axios.instance.defaults.headers.common["token"] = localStorage.getItem(
        "token"
      );
      let data = new FormData();
      for (const file of state.files) {
        data.append("file", file, file.name);
      }
      data.append("ticket_id", state.ticket_id);
      let response = await axios.instance.post("/tickets_media", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.data) {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: response.data.data, type: "success" },
          { root: true }
        );
      }
    } catch (error) {
      if (error) {
        //pass
      }
    }
  },
  async generateTicket({ dispatch, state, commit }) {
    try {
      if (state.device_id) {
        await commit("Loader/showLoader", null, { root: true });
        if (state.device_id.length) {
          await commit('setListOfDevices')
          for (let index = 0; index < state.listOfDevices.length; index++) {
              await commit('setDeviceId',state.listOfDevices[index])
              await dispatch("addTicket");
              await dispatch("addImagesTickets");
              
          }
        } else {
          await dispatch("addTicket");
          await dispatch("addImagesTickets");
        }
      } else {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: "No has seleccionado un dispositivo", type: "warning" },
          { root: true }
        );
      }
        await commit('afterGenerateTicket')
        await commit('Loader/closeLoader',null,{root:true})
    } catch (error) {
      if (error) {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: "Ocurrió un error al generar el ticket", type: "error" },
          { root: true }
        );
      }
    }
  },
  async getDevices({ commit, dispatch }) {
    try {
      axios.instance.defaults.headers.common["token"] = localStorage.getItem(
        "token"
      );
      let response = await axios.instance.get("/device/ext_usrs");
      if (response.data.data) {
        commit("setDevices", response.data.data);
      }
    } catch (error) {
      if (error) {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: "Hubo un error al obtener los dispositivos", type: "error" },
          { root: true }
        );
      }
    }
  },
  async getAllDevices({ commit, dispatch }) {
    try {
      axios.instance.defaults.headers.common["token"] = localStorage.getItem(
        "token"
      );
      let response = await axios.instance.get("/device/ext_usrs?all=true");
      if (response.data.data) {
        commit("setDevicesTable", response.data.data);
      }
    } catch (error) {
      if (error) {
        await dispatch(
          "Snackbars/setSnackbars",
          { text: "Hubo un error al obtener los dispositivos", type: "error" },
          { root: true }
        );
      }
    }
  },
  async countDevices({ state }) {
    return state.devices.length;
  },
  async DeviceHasActiveTickets({ state, commit, dispatch }) {
    try {
      axios.instance.defaults.headers.common["token"] = localStorage.getItem(
        "token"
      );
      let response = await axios.instance.get(
        "/device/ext_usrs?id=" + state.device_id
      );
      if (response.data.data == false) {
        commit("setSelectedDeviceHasTicket", false);
      } else {
        commit("setSelectedDeviceHasTicket", true);
      }
    } catch (error) {
      if (error) {
        await dispatch(
          "Snackbars/setSnackbars",
          {
            text: "Hubo un error al obtener los tickets del dispositivo",
            type: "error",
          },
          { root: true }
        );
      }
    }
  },
  async selectAllDevices({commit,state},value){
    commit('setAllDevices',value)
    commit('setDeviceId',value?state.devices.map(v=>{return v.value}):[])
  }
};

const mutations = {
  async setAllDevices(state,value){
    state.allDevices=value
  },
   async setListOfDevices(state){
        state.listOfDevices=state.device_id
   },
  async changeMultipleTicketsSwitch(state, value) {
    state.multipleTickets = value;
    state.device_id = null;
  },
  async dialogState(state, status) {
    state.dialog = status;
  },
  async setMultimedia(state, data) {
    state.files = data;
  },
  async setPhoneNumber(state, data) {
    state.phone_number = data;
  },
  async setEmail(state, data) {
    state.email = data;
  },
  async setDescription(state, data) {
    state.description = data;
  },
  async setDevices(state, data) {
    state.devices = data;
  },
  async setDevicesTable(state,data){
    state.devicesTable = data
  },
  async setDeviceId(state, data) {
    state.device_id = data;
    if(data.length>0){
      state.devices_id=data.map((id)=>{return {device_id:id}})
    }  
  },
  async setTicketId(state, data) {
    state.ticket_id = data;
  },
  async setSelectedDeviceHasTicket(state, data) {
    state.SelectedDeviceHasTicket = data;
  },
  async afterGenerateTicket(state) {
    state.files = [];
    state.phone_number = "";
    state.email = "";
    state.description = "";
    state.device_id = 0;
    state.ticket_id = 0;
    state.SelectedDeviceHasTicket = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
