import axios from '../../mixins/axiosInstance';
//import qs from 'qs';

const state = () => ({
    headers: [
        {
            text: "N° ticket",
            sortable: true,
            value: "ticket_id"
        },
        {
            text: "Supervisor",
            sortable: true,
            value: "advisor"
        },
        {
            text: "Creado por",
            sortable: false,
            value: "createdBy"
        },
        {
            text: "Cuenta",
            sortable: true,
            value: "account"
        },
        {
            text: "Tipo de dispositivo",
            sortable: true,
            value: "type"
        },
        {
            text: "Fecha Inicio",
            sortable: true,
            value: "init_date"
        },
        {
            text: "Fecha Fin",
            sortable: true,
            value: "end_date"
        },
        {
            text: "Estado",
            sortable: true,
            value: "status"
        }
    ],
    items: [],
    items_per_page: 5,
    loading: false,
    loading_text: "Cargando...",
    item_key: "ticket_id",
    no_data_text: "No hay registros historicos",
    search: ""
})

const getters = {}

const actions = {
    async fetchHistoricalTickets({commit}){
        try {
            let data;
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            data = await axios.instance.get("/tickets/user")
            //console.log(data.data.data)
            if(data.data.data != 'User has not tickets'){
                
                commit('setItems', data.data.data)
            }
        } catch (error) {
            if(error){
                alert('Ocurrió un error al obtener los tickets historicos')
            }
        }
    }
}

const mutations = {
    async asignItems(state, data){
        state.items = data
    },
    async loading(state, status){
        state.loading = status
    },
    async asignSearchTerm(state, term){
        state.search = term
    },
    async setItems(state, data){
        state.items = data
    }
}
  
export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
  }