import Vue from 'vue'
import Vuex from 'vuex'
import table from './modules/table'
import auth from './modules/auth'
import ticketsForm from './modules/ticketsForm'
import Reports from './modules/reports'
import Snackbars from './modules/Snackbar'
import Loader from './modules/Loader'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        table,
        auth,
        ticketsForm,
        Reports,
        Snackbars,
        Loader
    },
    strict: debug,
  
})