import axiosVersion from '../../mixins/axiosInsanceVersion'
// import qs from 'qs';

const state = () => ({
    reportDialog:null,
    typeOfFile: 'EXCEL',
    dateInitReport:{
        date:'',
        dialog:false
    },
    dateEndReport:{
        date:'',
        dialog:false
    }
})

const getters = {}

const actions = {
    async openServiceReportDialog({commit})
    {
        commit('openServiceReportDialog')
        commit('ticketsForm/setDeviceId',undefined,{root:true})
    },
    async closeServiceReportDialog({commit}){
        commit('closeServiceReportDialog')
        commit('ticketsForm/setDeviceId',undefined,{root:true})
    },
    async getReportByDevice({state,rootState,commit,dispatch}){
        try {
            var type
            if(state.typeOfFile==='PDF')
            {
                type='pdf'
            }else
            {
                type='csv'
            }
            await commit('closeServiceReportDialog')
            await commit('Loader/showLoader',null,{root:true})
            const axiosConn =axiosVersion.instance('v3')
            axiosConn.defaults.headers.common['token'] = localStorage.getItem("token");
            var ReportByRadio= await axiosConn.post("/tickets/devicesReport",{
                type:state.typeOfFile,
                devices:rootState.ticketsForm.devices_id,
                date_init:state.dateInitReport.date,
                date_end:state.dateEndReport.date
            },{responseType: 'arraybuffer'});
            console.log('report radio ',ReportByRadio)
            var blob = new File([ReportByRadio.data], {type:"application/"+type});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "reporte."+type
            link.click()
           await dispatch('Snackbars/setSnackbars',{text:'El reporte se descargo con exito',type:'success'},{root:true})
        } catch (error) {
           if(error=='Error: Request failed with status code 404')
            {
                await dispatch('Snackbars/setSnackbars',{text:'El dispositivo no tiene servicios',type:'warning'},{root:true})
            }else{
                await dispatch('Snackbars/setSnackbars',{text:'No se pudo descargar el reporte',type:'error'},{root:true})
            }
            await commit('openServiceReportDialog')
        }
       
        await commit('Loader/closeLoader',null,{root:true})
        
    },
}

const mutations = {
    async openServiceReportDialog(state){
        state.reportDialog=true
    },
    async updateTypeOfFile(state,value){
        state.typeOfFile=value
    },
    async closeServiceReportDialog(state){
        state.reportDialog=false
    },
    async updateDateInitReport(state,value){
        state.dateInitReport.date=value
    },
    async updateDateEndReport(state,value){
        state.dateEndReport.date=value
    }
}
  
export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
  }