<template>
    <v-app id="inspire">
      <router-view></router-view>
      <Snackbar/>
      <Loader/>
    </v-app>
</template>

<script>
import Snackbar from './components/Snackbar'
import Loader from './components/Loader'
export default {
  components: {
    Snackbar,
    Loader
  },
}
</script>