import axios from 'axios';
const hostname = window && window.location && window.location.hostname
function environment(){
    if(hostname==='127.0.0.1'||hostname==='localhost'){
        return `https://www.developcrmback.radiotrunk.com/api/v2`
    }else if(hostname==='142.93.18.134:9000'){
        return `https://www.developcrmback.radiotrunk.com/api/v2`
    }else if(hostname==='tickets.radiotrunk.com'){
        return `https://www.productioncrmback.radiotrunk.com/api/v2`
    }
}

const instance = axios.create({
    baseURL: environment(),
    timeout: 0,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded'}
});

export default{instance}