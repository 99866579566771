import Vue from 'vue'
import VueRouter from 'vue-router'
import isValidToken from '../mixins/isValidToken'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => {
      if(!localStorage.token || !isValidToken.isValidToken()){
        return import('../views/Login.vue');
      }
      else{
        return import('../views/Home.vue');
      }
    }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  routes
})

export default router
